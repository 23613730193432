import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {catchError, tap} from "rxjs/operators";
import {JwtHelperService} from "./jwt/jwthelper.service";
import {ReplaySubject} from "rxjs";
import {User} from "../user";
import {AppConfig} from "../../content/util/config/app-config";
import {CookieService} from "../../content/util/cookie/cookie.service";

export type Role = 'LINKBUILDER' | 'PARTNER' | 'EDITOR' | 'ADMIN';

@Injectable({providedIn: 'root'})
export class AuthService {

  private replaySubject = new ReplaySubject<User>(1);

  public user: Observable<User> = this.replaySubject.asObservable();
  public redirectUrl: string = "/partner";

  constructor(private http: HttpClient, private router: Router, private jwtHelper: JwtHelperService, private config: AppConfig, private cookie: CookieService) {
    if (this.authenticated()) {
      this.loadUser();
    }
  }


  private loadUser() {
    if (this.config.isServer)
      return;

    this.http
      .get<User>(environment.endpoints.users + "/me")
      .pipe(
        catchError(error => {
          this.logout();
          throw (error.error.message || 'Auth error');
        }))
      .subscribe(user => {
        this.replaySubject.next(user);
        this.replaySubject.complete();
      });
  }

  public login(email: string, password: string): Observable<any> {
    return this.http
      .post<Response>(environment.endpoints.users + "/login", {email: email, password: password})
      .pipe(tap(response => {
        // valid login request, save token and load user data
        this.cookie.set("access_token", response.message, 31, "/", true);
        this.loadUser();
      }));
  }

  public confirm(email: string, confirmationCode: string): Observable<any> {
    return this.http
      .post(environment.endpoints.users + "/confirm", {email: email, confirmationCode: confirmationCode})
      .pipe(tap(response => {
        // valid login request, save token and load user data
        this.cookie.set("access_token", response.message, 7);
        this.loadUser();
      }));
  }

  public signup(user: User): Observable<any> {
    return this.http
      .post(environment.endpoints.users + "/", user);
  }

  public authenticated() {
    // no user-data on the server
    if (this.config.isServer)
      return false;

    return !this.jwtHelper.isTokenExpired();
  }

  public getUserId() {
    if (!this.authenticated)
      return null;

    let token = this.jwtHelper.decodeToken();
    return token.id;
  }

  public hasRole(role: Role) {
    if (this.authenticated()) {
      let token = this.jwtHelper.decodeToken();
      if (token && token.roles.includes(role))
        return true;
    }
    return false;
  }

  public isEditor() {
    if (this.authenticated()) {
      let token = this.jwtHelper.decodeToken();
      if (token && (token.roles.includes("EDITOR") || token.roles.includes("ADMIN")))
        return true;
    }
    return false;
  }

  public isAdmin() {
    if (this.authenticated()) {
      let token = this.jwtHelper.decodeToken();
      if (token && token.roles.includes("ADMIN"))
        return true;
    }
    return false;
  }

  public logout() {
    this.cookie.delete('access_token');
    this.router.navigate(["/"]);
  }

}
